import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from './components/Footer';
import SocialIcons from './components/SocialIcons';
import logo from './assets/unplug-logo.png';
import Events from './pages/Events';
import Admin from './pages/Admin';
import LoadingScreen from './components/LoadingScreen';
import { MapProvider } from './contexts/MapContext';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { backgroundVariants } from './utils/backgroundAnimation';
import EventDetails from './pages/EventDetails';
import BookEvent from './pages/BookEvent';
import NotFound from './pages/NotFound';
import HomePage from './pages/Home';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <MapProvider>
        <motion.div
          className="min-h-screen flex flex-col text-white"
          variants={backgroundVariants}
          initial="initial"
          animate="animate"
        >
          <header className="header-container">
            <div className="header-logo">
              <Link to="/">
                <img src={logo} alt="Unplug Logo" className="header-logo-img" />
              </Link>
              <SocialIcons />
            </div>
          </header>

          <div className="flex-grow flex flex-col items-center">
            <Routes>
              <Route path="/" element={isLoading ? <LoadingScreen /> : <Events />} />
              <Route path="/events" element={<Events />} />
              <Route path="/admin" element={<Admin />} /> d
              <Route path="/event/:id" element={<EventDetails />} />
              <Route path="/book-event/:id" element={<BookEvent />} />
              <Route path="*" element={<NotFound />} /> 
              <Route path="/home" element={<HomePage />} />
            </Routes>
          </div>

          <Footer />
        </motion.div>
      </MapProvider>
    </Router>
  );
}

export default App;
