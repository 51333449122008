import React from 'react';
import { motion } from 'framer-motion';
import { FaInstagram, FaTiktok } from 'react-icons/fa';

const socialIconVariants = {
  hover: {
    scale: 1.2,
    color: '#FFD9A1', // Color on hover
  },
};

const SocialIcons = () => {
  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/unplug.uk/?hl=en', '_blank', 'noopener,noreferrer');
  };

  const handleTiktokClick = () => {
    window.open('https://www.tiktok.com/@unplug.uk?lang=en', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="social-icons flex justify-center space-x-4 sm:space-x-6 md:space-x-8 lg:space-x-10">
      <motion.div
        onClick={handleInstagramClick}
        onTouchEnd={handleInstagramClick}
        className="group cursor-pointer"
        variants={socialIconVariants}
        whileHover="hover"
      >
        <FaInstagram
          className="text-4xl sm:text-5xl md:text-4xl lg:text-6xl group-hover:text-[#FFD9A1] transition-colors duration-300"
        />
      </motion.div>
      <motion.div
        onClick={handleTiktokClick}
        onTouchEnd={handleTiktokClick}
        className="group cursor-pointer"
        variants={socialIconVariants}
        whileHover="hover"
      >
        <FaTiktok
          className="text-4xl sm:text-5xl md:text-4xl lg:text-6xl group-hover:text-[#FFD9A1] transition-colors duration-300"
        />
      </motion.div>
    </div>
  );
};

export default SocialIcons;
