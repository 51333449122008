// src/backgroundAnimation.js

export const colors = ['#FF9700', '#FF5C00', '#FFC700'];

export const backgroundVariants = {
  initial: {
    background: `radial-gradient(circle, ${colors[2]}, ${colors[0]})`,
  },
  animate: {
    background: `radial-gradient(circle, ${colors[1]}, ${colors[2]})`,
    transition: {
      ease: 'easeInOut',
      duration: 10,
      repeat: Infinity,
      repeatType: 'mirror',
    },
  },
};

export const textVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
    },
  },
};
