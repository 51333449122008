import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaClock, FaCheck, FaCalendarPlus, FaInstagram, FaWhatsapp, FaSms } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import db from '../firebase';

const BookEvent = () => {
  const location = useLocation();
  const { id } = useParams(); // Get the event ID from URL parameters

  const [event, setEvent] = useState(location.state?.event || null);
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', phoneNumber: '' });
  const [errors, setErrors] = useState({});
  const [isSoldOut] = useState(false);
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);

  useEffect(() => {
    const fetchEventById = async (eventId) => {
      try {
        const eventDoc = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventDoc);
        if (eventSnapshot.exists()) {
          setEvent({ id: eventSnapshot.id, ...eventSnapshot.data() });
        } else {
          console.error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    if (!event && id) {
      fetchEventById(id);
    }
  }, [event, id]);

  const validate = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = 'First name is required';
    if (!formData.lastName) errors.lastName = 'Last name is required';
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset bookingConfirmed when starting a new booking
    setBookingConfirmed(false);

    const validationErrors = validate();
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      setShowConfirmationMessage(false); // Hide confirmation if there are validation errors
      return;
    }

    // Perform email check before showing confirmation message
    try {
      if (!event || !event.id) {
        console.error('Event is not defined or does not have an ID.');
        setShowConfirmationMessage(false);
        return;
      }

      const bookingsQuery = query(
        collection(db, 'bookings'),
        where('event_id', '==', event.id),
        where('email', '==', formData.email)
      );
      const existingBookingSnapshot = await getDocs(bookingsQuery);

      if (!existingBookingSnapshot.empty) {
        setErrors({ email: 'This email has already been used to book this event.' });
        setShowConfirmationMessage(false); // Hide confirmation if email is already used
        return;
      }

      // If no errors, show confirmation message
      setShowConfirmationMessage(true);
    } catch (error) {
      console.error('Error checking email:', error);
      setShowConfirmationMessage(false);
    }
  };

  const confirmBooking = async () => {
    try {
      if (!event || !event.id) {
        console.error('Event is not defined or does not have an ID.');
        setShowConfirmationMessage(false);
        return;
      }

      const bookingRef = doc(collection(db, 'bookings'));
      await setDoc(bookingRef, {
        event_id: event.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        ticketQuantity,
        bookingDate: new Date(),
      });

      setShowConfirmationMessage(false); // Hide confirmation message after successful booking
      setBookingConfirmed(true);
    } catch (error) {
      console.error('Error submitting booking:', error);
      setShowConfirmationMessage(false); // Hide confirmation if an error occurs
    }
  };

  const cancelBooking = () => {
    setShowConfirmationMessage(false);
  };

  const shareViaInstagram = () => {
    window.open('https://www.instagram.com/unplug.uk/?hl=en', '_blank');
  };

  const shareViaWhatsApp = () => {
    // Construct the event-specific URL
    const eventUrl = `https://www.theunplug.club/event/${event.id}`;
    // Construct the message text with the event URL
    const text = `Come with me to this event: ${eventUrl}`;
    // Open WhatsApp with the message
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`, '_blank');
  };

  const shareViaMessage = () => {
    // Construct the event-specific URL
    const eventUrl = `https://www.theunplug.club/event/${event.id}`;
    // Construct the message text with the event URL
    const text = `Come with me to this event: ${eventUrl}`;
    // Open message app with the message
    window.open(`sms:?body=${encodeURIComponent(text)}`, '_blank');
  };

  const handleAddToCalendar = () => {
    try {
      const calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.name)}&details=${encodeURIComponent(event.description || '')}`;
      window.open(calendarUrl, '_blank');
    } catch (error) {
      console.error('Error creating calendar event:', error);
    }
  };

  if (!event) {
    return (
      <div className="container mx-auto p-4 text-center">
        <p>Loading...</p>
      </div>
    );
  }

  const pricePerTicket = parseFloat(event.cost) || 0;
  const totalPrice = pricePerTicket * ticketQuantity;

  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <motion.div className="relative rounded-xl w-full max-w-lg mx-auto">
        <div className="text-center mb-4">
          <h1 className="text-4xl lg:text-6xl font-bold">Book Event</h1>
        </div>
  
        <div className="flex flex-col gap-4 items-center">
          <div className="w-full max-w-lg border-2 border-black bg-white p-4 rounded-lg shadow-lg text-black">
            <div className="flex items-start justify-between">
              <h2 className="text-2xl font-bold mb-4">{event.name}</h2>
              <button
                onClick={handleAddToCalendar}
                className="tight-button scale-animation"
              >
                <FaCalendarPlus className="text-lg" />
              </button>
            </div>
            <p
              className="text-xl flex items-center cursor-pointer mb-2 transition-all duration-300 hover:underline"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps?q=${event.latitude},${event.longitude}`,
                  "_blank"
                )
              }
            >
              <FaMapMarkerAlt className="mr-2" />
              {event.location}
            </p>
            <p className="text-xl mb-4 flex items-center">
              <FaClock className="mr-2" />
              {event.date_str} | {event.start_time} - {event.end_time}
            </p>
  
            <div className="flex items-center mb-4">
              <span className="text-xl font-bold mr-8">Tickets:</span>
              <button
                onClick={() =>
                  setTicketQuantity(Math.max(1, ticketQuantity - 1))
                }
                className="tight-gradient-button"
              >
                -
              </button>
              <span className="mx-2 text-xl">{ticketQuantity}</span>
              <button
                onClick={() => setTicketQuantity(ticketQuantity + 1)}
                className="tight-gradient-button mr-8"
              >
                +
              </button>
              <span className="ml-4 text-xl font-bold">
                {totalPrice === 0
                  ? "Free"
                  : `£${new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    })
                      .format(totalPrice)
                      .replace("£", "")}`}
              </span>
            </div>
  
            <hr className="my-2 border-1 border-black" />
            <p className="text-xl text-center">
              It's more fun to go with friends. Share with them below.
            </p>
  
            <div className="flex justify-center gap-6 mt-2">
              <button
                onClick={shareViaInstagram}
                className="tight-button scale-animation"
              >
                <FaInstagram className="text-xl" />
              </button>
              <button
                onClick={shareViaWhatsApp}
                className="tight-button scale-animation"
              >
                <FaWhatsapp className="text-xl" />
              </button>
              <button
                onClick={shareViaMessage}
                className="tight-button scale-animation"
              >
                <FaSms className="text-xl" />
              </button>
            </div>
  
            {/* Form Details Below Social Icons */}
            <div className="mt-4">
            <h2 className="text-2xl font-bold mb-4">your details</h2>
              {!bookingConfirmed ? (
                <>
                  {!showConfirmationMessage ? (
                    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                      <div className="flex flex-col sm:flex-row gap-2">
                        <div className="w-full sm:w-1/2">
                          <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="First name"
                            className="p-3 bg-white border-2 border-black text-black rounded-lg w-full"
                            required
                          />
                          {errors.firstName && (
                            <p className="text-red-500 text-sm mt-2">
                              {errors.firstName}
                            </p>
                          )}
                        </div>
                        <div className="w-full sm:w-1/2">
                          <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Last name"
                            className="p-3 bg-white border-2 border-black text-black rounded-lg w-full"
                            required
                          />
                          {errors.lastName && (
                            <p className="text-red-500 text-sm mt-2">
                              {errors.lastName}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Email"
                          className="p-3 bg-white border-2 border-black text-black rounded-lg w-full"
                          required
                        />
                        {errors.email && (
                          <p className="text-red-500 text-sm mt-2">
                            {errors.email}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col sm:flex-row gap-2">
                        <div className="w-full sm:w-full">
                          <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder="Phone number"
                            className="p-3 bg-white border-2 border-black text-black rounded-lg w-full"
                            required
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="bg-black text-white py-3 px-4 rounded-lg font-semibold"
                      >
                        <FaCheck className="inline mr-2" />
                        Confirm Booking
                      </button>
  
                      {isSoldOut && (
                        <div className="bg-red-500 text-white p-4 rounded-lg mb-2">
                          <h3 className="text-xl font-bold">Sold Out</h3>
                          <p>Sorry, this event is fully booked.</p>
                        </div>
                      )}
                    </form>
                  ) : (
                    <div className="p-4 border-2 border-gray-800 rounded-lg bg-white text-black w-full max-w-lg">
                      <h3 className="text-xl font-bold mb-2">
                        Confirm Your Booking
                      </h3>
                      <p className="mb-2 text-lg">
                        You are about to book {ticketQuantity} ticket(s) for this
                        event.
                      </p>
                      <span className="font-semibold text-xl">
                        {totalPrice === 0
                          ? "Free"
                          : `£${new Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: "GBP",
                            })
                              .format(totalPrice)
                              .replace("£", "")}`}
                      </span>
                      <div className="flex gap-4 mt-2">
                        <button
                          onClick={confirmBooking}
                          className="bg-green-500 text-white py-2 px-4 rounded-lg font-semibold"
                        >
                          Confirm
                        </button>
                        <button
                          onClick={cancelBooking}
                          className="bg-red-500 text-white py-2 px-4 rounded-lg font-semibold"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="p-4 bg-green-500 text-white rounded-lg w-full max-w-lg">
                  <h3 className="text-2xl font-bold mb-2">Booking Confirmed</h3>
                  <p className="text-xl">
                    Your booking for {event.name} event has been successfully
                    confirmed.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BookEvent;
