import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoidW5wbHVnamFjb2IiLCJhIjoiY2x4eW1hNjhoMDI5ZjJpcjJ6amY4bHY4aiJ9.bEQu354oGlWyzkpYpMsPrA';

const MapComponent = ({ events, selectedEvent, title, logo }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-1.5768); // Longitude for London
  const [lat, setLat] = useState(51.2731);  // Latitude for London
  const [zoom, setZoom] = useState(7);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [lng, lat],
      zoom: zoom,
      attributionControl: false,
    });

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [lng, lat, zoom]);

  useEffect(() => {
    if (map.current && events.length) {
      markers.forEach(marker => marker.remove());
      setMarkers([]);

      const newMarkers = events.map(event => {
        const { latitude, longitude, name, location, date, start_time, end_time } = event;
        const el = document.createElement('div');
        el.className = `marker ${selectedEvent && selectedEvent.id === event.id ? 'selected' : ''}`;

        const popupHTML = `
          <div class="text-left">
            <h3 class="text-lg font-bold text-orange-500 mb-1">${name}</h3>
            <p class="text-sm text-gray-700">${location}</p>
            <p class="text-sm text-gray-600">${date} | ${start_time} - ${end_time}</p>
          </div>
        `;

        return new mapboxgl.Marker(el)
          .setLngLat([longitude, latitude])
          .setPopup(new mapboxgl.Popup({ offset: 15 }).setHTML(popupHTML))
          .addTo(map.current);
      });

      setMarkers(newMarkers);
    }
  }, [events, selectedEvent, markers]);

  useEffect(() => {
    if (selectedEvent && map.current) {
      const { latitude, longitude } = selectedEvent;

      map.current.flyTo({
        center: [longitude, latitude],
        zoom: 14,
        essential: true,
      });
    }
  }, [selectedEvent]);

  return (
    <div className="relative w-full h-96 rounded-lg">
      <div className="absolute top-0 left-0 text-[#FF9000] p-2 text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold z-10 single-line fade-in">
        {title}
      </div>
      <div className="absolute bottom-0 right-0 p-4 z-10 ">
        <img src={logo} alt="Logo" className="h-16" />
      </div>
      <div ref={mapContainer} className="w-full h-full rounded-lg" />
    </div>
  );
};

export default MapComponent;
