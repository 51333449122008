import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { collectionSchema } from '../utils/collectionSchemas';
// import CloseButton from '../CloseButton'; // Ensure you have a similar close button component

const AddDocumentModal = ({ isOpen, onClose, onConfirm, collectionName }) => {
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const schema = collectionSchema[collectionName] || {};

  const handleInputChange = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
    setError(''); // Clear error when input changes
  };

  const handleConfirm = () => {
    // Validate form values against schema
    const isValid = Object.entries(schema).every(([key, type]) => {
      const value = formValues[key];
      if (type === 'timestamp') {
        return value && !isNaN(new Date(value).getTime());
      } else if (type === 'number') {
        return value && !isNaN(Number(value));
      } else if (type === 'string') {
        return typeof value === 'string';
      } else {
        return false; // Unsupported type
      }
    });

    if (!isValid) {
      setError('Please ensure all fields are correctly filled out according to the schema.');
      return;
    }

    // If valid, call onConfirm with the form values and collection name
    onConfirm(formValues, collectionName);
    setFormValues({}); // Optionally clear form values
  };

  const renderInput = (field, type) => {
    if (type === 'timestamp') {
      return (
        <input
          id={field}
          type="datetime-local"
          value={formValues[field] || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          className="p-3 border-2 border-black text-black rounded-lg w-full"
          placeholder={`Enter ${field}`}
        />
      );
    } else if (type === 'number') {
      return (
        <input
          id={field}
          type="number"
          value={formValues[field] || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          className="p-3 border-2 border-black text-black rounded-lg w-full"
          placeholder={`Enter ${field}`}
        />
      );
    } else {
      return (
        <input
          id={field}
          type="text"
          value={formValues[field] || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          className="p-3 border-2 border-black text-black rounded-lg w-full"
          placeholder={`Enter ${field}`}
        />
      );
    }
  };

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="relative p-4 rounded-xl shadow-lg w-full max-w-md h-auto max-h-[90vh] overflow-y-auto"
           style={{
             background: 'radial-gradient(circle, #FFC700, #FF9700)', // Same gradient
           }}>
        {/* <CloseButton onClose={onClose} /> */}
        <h2 className="text-3xl font-bold mb-4 text-center text-white">Add Document</h2>
        {error && <p className="text-red-500 text-sm text-center mb-4">{error}</p>}
        <div className="space-y-4">
          {Object.entries(schema).map(([field, type]) => (
            <div key={field} className="flex flex-col">
              <label className="mb-1 font-semibold text-black" htmlFor={field}>
                {field}:
              </label>
              {renderInput(field, type)}
            </div>
          ))}
        </div>
        <div className="mt-6 flex flex-col sm:flex-row justify-end gap-4">
          <button onClick={handleConfirm} className="bg-black text-white py-2 px-4 rounded-lg font-semibold transition-transform transform hover:scale-105">
            Add Document
          </button>
          <button onClick={onClose} className="bg-black text-white py-2 px-4 rounded-lg font-semibold transition-transform transform hover:scale-105">
            Cancel
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default AddDocumentModal;