import React from 'react';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png';  // Make sure this is the correct path

const HomePage = () => {
  return (
    <motion.div 
      className="min-h-screen flex flex-col justify-between bg-unplug-orange text-black"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Logo */}
      <div className="absolute top-20 right-4 p-4">
        <img src={logo} alt="Unplug Logo" className="h-60" />
      </div>

      {/* Main Content */}
      <div className="flex-grow flex flex-col pl-6">
        <h1 className="text-8xl md:text-[calc(100vh/3.5)] font-bold text-left -mb-12 md:-mb-16">
          the
        </h1>
        <h1 className="text-8xl md:text-[calc(100vh/3.5)] font-bold text-left -mb-12 md:-mb-16">
          unplug
        </h1>
        <h1 className="text-8xl md:text-[calc(100vh/3.5)] font-bold text-left">
          club.
        </h1>
      </div>

      {/* Footer with CTA */}
      <div className="absolute bottom-0 right-0 p-6 text-left">
        <p className="text-5xl md:text-6xl font-semibold text-[#FFD9A1]">see</p>
        <p className="text-5xl md:text-6xl font-semibold text-[#FFD9A1]">unplug</p>
        <p className="text-5xl md:text-6xl font-semibold text-[#FFD9A1]">events ↓</p>
      </div>
    </motion.div>
  );
};

export default HomePage;
