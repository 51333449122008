import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc, query, orderBy } from 'firebase/firestore'; // Include query and orderBy
import db from '../firebase';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import AddDocumentModal from '../components/AddDocumentModal';
import AdminLogin from '../components/AdminLogin';
import { collectionSchema } from '../utils/collectionSchemas'; // Assuming it's in utils folder

const AdminPage = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [documents, setDocuments] = useState([]);
  const [editDocId, setEditDocId] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    if (authenticated) {
      const fetchCollections = async () => {
        try {
          const collectionNames = ['events', 'users', 'bookings'];
          setCollections(collectionNames);
        } catch (err) {
          setError('Error fetching collections');
          console.error(err);
        } finally {
          setLoading(false);
        }
      };

      fetchCollections();
    }
  }, [authenticated]);

  useEffect(() => {
    if (selectedCollection) {
      const fetchDocuments = async () => {
        try {
          const collectionRef = collection(db, selectedCollection);
          let documentsQuery = collectionRef;

          if (sortField) {
            documentsQuery = query(collectionRef, orderBy(sortField, sortOrder));
          }

          const documentsSnapshot = await getDocs(documentsQuery);
          const documentsList = documentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setDocuments(documentsList);
        } catch (err) {
          setError('Error fetching documents');
          console.error(err);
        }
      };

      fetchDocuments();
    }
  }, [selectedCollection, sortField, sortOrder]);

  const handleAddDocument = async (newDocValues) => {
    try {
      const newDocRef = await addDoc(collection(db, selectedCollection), newDocValues);
      setDocuments([...documents, { id: newDocRef.id, ...newDocValues }]);
      setShowModal(false);
      alert('Document added successfully!');
    } catch (err) {
      console.error('Error adding document:', err);
      alert('Failed to add document. Please try again.');
    }
  };

  const handleUpdate = async (id) => {
    if (window.confirm('Are you sure you want to update this document?')) {
      try {
        const docRef = doc(db, selectedCollection, id);
        await updateDoc(docRef, formValues);
        setDocuments(docs => docs.map(doc => (doc.id === id ? { ...doc, ...formValues } : doc)));
        setEditDocId(null);
      } catch (err) {
        console.error('Error updating document:', err);
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        const docRef = doc(db, selectedCollection, id);
        await deleteDoc(docRef);
        setDocuments(docs => docs.filter(doc => doc.id !== id));
        setEditDocId(null);
      } catch (err) {
        console.error('Error deleting document:', err);
        alert('Failed to delete document. Please try again.');
      }
    }
  };

  const handleInputChange = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const startEditing = (doc) => {
    setEditDocId(doc.id);
    setFormValues(doc);
  };

  useEffect(() => {
    if (selectedCollection) {
      setFormValues({});
    }
  }, [selectedCollection]);

  if (!authenticated) {
    return <AdminLogin onLogin={() => setAuthenticated(true)} />;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4 single-line">admin</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div>
          <div className="flex items-center mb-4">
            <label htmlFor="collections" className="block text-lg font-semibold mr-4">Collections:</label>
            <select
              id="collections"
              value={selectedCollection}
              onChange={(e) => setSelectedCollection(e.target.value)}
              className="border p-2 mr-4 text-black rounded-lg"
            >
              <option value="">Select a collection</option>
              {collections.map((collection) => (
                <option key={collection} value={collection}>{collection}</option>
              ))}
            </select>
            {selectedCollection && (
              <>
                <div className="flex items-center">
                  <label htmlFor="sortField" className="block text-lg font-semibold mr-4 single-line">Sort By:</label>
                  <select
                    id="sortField"
                    value={sortField}
                    onChange={(e) => setSortField(e.target.value)}
                    className="border p-2 mr-4 text-black rounded-lg"
                  >
                    <option value="">Select a field</option>
                    {Object.keys(collectionSchema[selectedCollection]).map((field) => (
                      <option key={field} value={field}>{field}</option>
                    ))}
                  </select>
                  <label htmlFor="sortOrder" className="block text-lg font-semibold mr-4 single-line">Order:</label>
                  <select
                    id="sortOrder"
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                    className="border p-2 text-black rounded-lg mr-8"
                  >
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>

                  <button
                    onClick={() => setShowModal(true)}
                    className="flex black-button items-center justify-center"
                    >
                    <FaPlus className="mr-2" /> Add Document
                  </button>
                </div>
                <AddDocumentModal
                  isOpen={showModal}
                  onClose={() => setShowModal(false)}
                  onConfirm={handleAddDocument}
                  collectionName={selectedCollection}
                />
              </>
            )}
          </div>

          <div className="space-y-2">
            {documents.length > 0 ? (
              documents.map(doc => (
                <div
                  key={doc.id}
                  className="relative p-4 border rounded-lg shadow-lg bg-white"
                >
                  <div className="mb-2 text-xl font-semibold text-black">ID: {doc.id}</div>
                  <div className="absolute top-0 right-0 p-2 flex gap-2 opacity-100">
                    <button
                      onClick={() => startEditing(doc)}
                      className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(doc.id)}
                      className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                    >
                      <FaTrash />
                    </button>
                  </div>
                  <div className="text-gray-800">
                    {Object.entries(doc).map(([key, value]) => (
                      key !== 'id' && (
                        <div key={key} className="mb-2 flex items-center">
                          <strong className="mr-2">{key}:</strong>
                          {editDocId === doc.id ? (
                            <input
                              type="text"
                              value={formValues[key] || ''}
                              onChange={(e) => handleInputChange(key, e.target.value)}
                              className="border p-2 flex-grow"
                              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            />
                          ) : (
                            <span>{String(value)}</span>
                          )}
                        </div>
                      )
                    ))}
                    {editDocId === doc.id && (
                      <div className="flex justify-center gap-2 items-center">
                        <button
                          onClick={() => handleUpdate(doc.id)}
                          className="gradient-button mr-2 my-2"
                        >
                          Confirm Update
                        </button>
                        <button
                          onClick={() => setEditDocId(null)}
                          className="bg-black font-semibold text-white py-2 px-4 rounded-lg"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No documents found in this collection.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
