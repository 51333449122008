// src/utils/collectionSchemas.js
export const collectionSchema = {
    events: {
      name: 'string',
      date: 'timestamp', // Firestore timestamp type
      date_str:'string',
      location: 'string',
      longitude:'number',
      latitude:'number',
      start_time: 'timestamp',
      end_time: 'timestamp',
      cost: 'number',
      host: 'string',
      description: 'string',
      what_to_expect:'string',
      do_i_need_anything:'string',
      image: 'string',
      instagram_link:'string',
      venue_instagram:'string',
      capacity:'number',
    },
    users: {
      firstName: 'string',
      lastName: 'string',
      email: 'string',
    },
    bookings: {
      firstName: 'string',
      lastName: 'string',
      email: 'string',
      phoneNumber:'string',
      event_id: 'string',
      ticketQuantity: 'string',
      bookingDate: 'timestamp',
    },
    // Add other collection schemas as needed
  };
  