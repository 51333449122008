import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FaTicketAlt, FaMapMarkerAlt, FaClock, FaInstagram } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'; // Import doc and getDoc
import db from '../firebase'; // Adjust this import based on your project structure

// Helper function to get image path
const getImagePath = (imageName) => {
  try {
    return require(`../assets/${imageName}`);
  } catch (err) {
    console.error(`Image not found: ../assets/${imageName}`);
    return require('../assets/logo.png'); // Fallback image
  }
};

const EventDetails = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const location = useLocation();
  const { id } = useParams(); // Extract the event ID from the URL
  const [event, setEvent] = useState(location.state?.event);
  const [loading, setLoading] = useState(!event);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventById = async (eventId) => {
      try {
        const eventDoc = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventDoc);
        if (eventSnapshot.exists()) {
          setEvent({ id: eventSnapshot.id, ...eventSnapshot.data() });
          setLoading(false);
        } else {
          setError('Event not found');
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching event:', err);
        setError('Failed to fetch event');
        setLoading(false);
      }
    };

    if (!event) {
      fetchEventById(id);
    }
  }, [id, event]);

  if (loading) {
    return <p className="text-center text-white text-lg">Loading event details...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500 text-lg">{error}</p>; // Display error message
  }

  const imagePath = getImagePath(event.image || 'placeholder-image.jpg');

  const handleBookEvent = () => {
    navigate(`/book-event/${event.id}`, { state: { event } });
  };

  const mapsUrl = `https://www.google.com/maps?q=${event.latitude},${event.longitude}`;

  // Convert Firestore timestamp to JavaScript Date
  const eventDate = new Date(event.date.seconds * 1000);
  
  // Determine if the event is in the past
  const isPastEvent = eventDate < new Date();

  return (
    <motion.div
      className="container mx-auto p-4 md:p-6 lg:p-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-black mb-6">
        <div className="relative">
          <img
            src={imagePath}
            alt={event.name}
            className="w-full h-56 md:h-72 object-cover rounded-lg mb-6"
          />
          {!isPastEvent && ( // Conditionally render the Book Event button if the event is not in the past
            <button
              type="button"
              className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black text-white py-2 px-4 rounded-lg font-semibold flex justify-center items-center gap-2 transition-transform hover:scale-105"
              onClick={handleBookEvent}
              style={{ width: 'fit-content', padding: '0.5rem 1rem' }}
            >
              <FaTicketAlt className="text-xl" />
              Book Event
            </button>
          )}
        </div>
      </div>


      <div className="flex flex-col lg:flex-row lg:items-start w-full gap-4">
        {/* Event Details */}
        <div className="flex flex-col w-full bg-white text-black rounded-xl p-4 mb-4">
          <div className="flex flex-col md:flex-row md:justify-between mb-4 p-2">
            <div className="flex flex-col md:w-2/3">
              <h1 className="text-3xl md:text-4xl font-bold mb-4">{event.name}</h1>
              <div className="flex items-center text-base md:text-lg mb-3">
                <FaClock className="mr-3 text-xl md:text-2xl" />
                <span>{event.date_str} | {event.start_time} - {event.end_time}</span>
              </div>
              <div className="flex items-center text-base md:text-lg cursor-pointer transition-all duration-300 hover:underline">
                <FaMapMarkerAlt className="mr-3 text-xl md:text-2xl" />
                <a href={mapsUrl} target="_blank" rel="noopener noreferrer">{event.location}</a>
              </div>
              <div className="flex items-center text-base md:text-lg cursor-pointer transition-all duration-300 hover:underline mt-3">
                <FaInstagram className="mr-3 text-xl md:text-2xl" />
                <a href={event.venue_instagram} target="_blank" rel="noopener noreferrer">Check out the venue</a>
              </div>
            </div>
            <div className="flex flex-col md:items-end md:w-1/3 mt-4 md:mt-0">
              <p className="flex items-center text-base md:text-lg lg:text-xl">
                <span className="text-2xl md:text-3xl font-bold">{event.cost === 0 ? 'Free' : `£${new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(event.cost).replace('£', '')}`}</span>
              </p>
            </div>
          </div>

          <div className="bg-white p-2 rounded-lg text-black">
            <h2 className="text-xl md:text-2xl font-semibold mb-3">what to expect</h2>
            <ul className="list-disc list-inside text-sm md:text-base text-black leading-relaxed">
              {event.what_to_expect.split('. ').map((item, index) => (
                <li key={index}>{item.trim()}.</li>
              ))}
            </ul>

            <h2 className="mt-4 text-xl md:text-2xl font-semibold mb-3">do i need anything?</h2>
            <ul className="list-disc list-inside text-sm md:text-base text-black leading-relaxed">
              {event.do_i_need_anything.split('. ').map((item, index) => (
                <li key={index}>{item.trim()}.</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <iframe
            width="300"
            height="520"
            src={event.instagram_link}
            title="Instagram video"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture allowfullscreen"
            allowFullScreen
            loading="lazy"
            className="rounded-lg overflow-hidden"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default EventDetails;
