import React, { useContext, useState } from 'react';
import { MapContext } from '../contexts/MapContext';
import MapComponent from '../components/MapComponent';
import EventCard from '../components/EventCard';
import orangeLogo from '../assets/orange_logo.png';

// Separate events into upcoming and past
const separateEventsByTime = (events) => {
  const now = new Date();

  // Separate upcoming and past events
  const upcomingEvents = events.filter(event => event.date.toDate() >= now).sort((a, b) => a.date.toDate() - b.date.toDate());
  const pastEvents = events.filter(event => event.date.toDate() < now).sort((a, b) => b.date.toDate() - a.date.toDate());

  return { upcomingEvents, pastEvents };
};

const Events = () => {
  const { events, selectedEvent, setSelectedEvent } = useContext(MapContext);
  const [currentIndex, setCurrentIndex] = useState({});

  // Separate and sort events by time
  const { upcomingEvents, pastEvents } = separateEventsByTime(events);

  const handleNext = (category) => {
    setCurrentIndex((prevIndex) => ({
      ...prevIndex,
      [category]: (prevIndex[category] || 0) + 3,
    }));
  };

  const handlePrevious = (category) => {
    setCurrentIndex((prevIndex) => ({
      ...prevIndex,
      [category]: Math.max((prevIndex[category] || 0) - 3, 0),
    }));
  };

  return (
    <div className="container mx-auto px-4">
      <div className="text-center mb-4">
        <h1 className="text-5xl md:text-6xl lg:text-8xl font-bold text-white single-line">
          unplug events
        </h1>
      </div>

      <div className="flex justify-center">
        <div className="w-full max-w-5xl">

          {/* Display Upcoming Events */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-5">
              <button
                onClick={() => handlePrevious('upcoming')}
                className={`text-white text-4xl button-animation ${currentIndex['upcoming'] === 0 ? 'text-gray-500 cursor-not-allowed' : ''}`}
                disabled={currentIndex['upcoming'] === 0}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
              <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white single-line">
                Upcoming Events
              </h2>
              <button
                onClick={() => handleNext('upcoming')}
                className={`text-white text-4xl button-animation ${currentIndex['upcoming'] + 3 >= upcomingEvents.length ? 'text-gray-500 cursor-not-allowed' : ''}`}
                disabled={currentIndex['upcoming'] + 3 >= upcomingEvents.length}
              >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>

            {/* Display Upcoming Events */}
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              {upcomingEvents.slice(currentIndex['upcoming'] || 0, (currentIndex['upcoming'] || 0) + 3).map(event => (
                <EventCard
                  key={event.id}
                  event={event}
                  isPastEvent={false}
                  onSelectEvent={setSelectedEvent}
                />
              ))}
            </div>
          </div>

          {/* Display Past Events */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-5">
              <button
                onClick={() => handlePrevious('past')}
                className={`text-white text-4xl button-animation ${currentIndex['past'] === 0 ? 'text-gray-500 cursor-not-allowed' : ''}`}
                disabled={currentIndex['past'] === 0}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
              <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white single-line">
                Past Events
              </h2>
              <button
                onClick={() => handleNext('past')}
                className={`text-white text-4xl button-animation ${currentIndex['past'] + 3 >= pastEvents.length ? 'text-gray-500 cursor-not-allowed' : ''}`}
                disabled={currentIndex['past'] + 3 >= pastEvents.length}
              >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>

            {/* Display Past Events */}
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              {pastEvents.slice(currentIndex['past'] || 0, (currentIndex['past'] || 0) + 3).map(event => (
                <EventCard
                  key={event.id}
                  event={event}
                  isPastEvent={true}
                  onSelectEvent={setSelectedEvent}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="relative mb-4">
        <MapComponent
          events={events}
          selectedEvent={selectedEvent}
          title={
            <div>
              <h2>unplug</h2>
              <h2 className="-mt-1 md:-mt-2 lg:-mt-4">event map</h2>
            </div>
          }
          logo={orangeLogo}
        />
      </div>
    </div>
  );
};

export default Events;
