import React from 'react';
import { FaMapMarkerAlt, FaClock, FaTicketAlt, FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { Timestamp } from 'firebase/firestore';

const getImagePath = (imageName) => {
  try {
    return require(`../assets/${imageName}`);
  } catch (err) {
    console.error(`Image not found: ../assets/${imageName}`);
    return require('../assets/logo.png');
  }
};

const formatDate = (timestamp) => {
  const date = timestamp instanceof Timestamp ? timestamp.toDate() : new Date(timestamp);
  const options = {day: '2-digit' , month: 'short' };
  return date.toLocaleDateString('en-UK', options);
};

const EventCard = ({ event, isPastEvent }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const imagePath = getImagePath(event.image || 'placeholder-image.jpg');
  const formattedDate = formatDate(event.date);

  const handleCardClick = () => {
    navigate(`/event/${event.id}`, { state: { event } });
  };

  const handleBookEventClick = (e) => {
    e.stopPropagation(); // Prevent triggering the card click handler
    navigate(`/book-event/${event.id}`, { state: { event } }); // Navigate to BookEventScreen with event data
  };

  return (
    <div className="relative">
      <div className="event-card bg-white rounded-lg shadow-md overflow-hidden cursor-pointer">
        <div className="relative">
          <img src={imagePath} alt={event.name} className="w-full h-52 object-cover" />
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-white text-4xl font-bold p-2 rounded-lg bg-black bg-opacity-50">{formattedDate}</span>
          </div>

          {/* Information Icon */}
          <div className="absolute top-2 right-2">
            <FaInfoCircle 
              onTouchEnd={handleCardClick} 
              onClick={handleCardClick}
              className="text-white text-2xl cursor-pointer" 
            />
          </div>
        </div>

        <div className="event-details p-2 flex flex-col -mb-6">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-2xl font-semibold text-black">{event.name}</h2>
            <span className="text-xl font-bold text-black">{event.cost === 0 ? 'Free' : `£${new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(event.cost).replace('£', '')}`}</span>
          </div>
          <div 
            className="text-xl text-gray-800 flex items-center cursor-pointer transition-all duration-300 hover:underline"
            onClick={() => window.open(`https://www.google.com/maps?q=${event.latitude},${event.longitude}`, '_blank')}
            onTouchEnd={() => window.open(`https://www.google.com/maps?q=${event.latitude},${event.longitude}`, '_blank')}
          >
            <FaMapMarkerAlt className="mr-2" />
            {event.location}
          </div>
          <div className="text-xl text-gray-800 flex items-center">
            <FaClock className="mr-2" />
            {event.start_time} - {event.end_time}
          </div>
        </div>

        {!isPastEvent && (
          <div className="p-2 flex flex-row items-center gap-2">
            <button 
              className="black-button"
              onTouchEnd={handleBookEventClick}
              onClick={handleBookEventClick} // Navigate to booking screen on button click
            >
              <FaTicketAlt className="inline mr-2" />
              book event
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
