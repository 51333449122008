// src/components/AdminLogin.js

import React, { useState } from 'react';

const AdminLogin = ({ onLogin }) => {
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    if (password === process.env.REACT_APP_ADMIN_PASSWORD) {
      onLogin();
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div className="container mx-auto p-4">
        <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4 single-line text-center">admin login</h1>
        <div className="flex flex-col items-center">
            <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-3 mb-4 border-2 border-black rounded-lg text-black w-1/3"
            placeholder="Enter admin password"
            />
            <button
            onClick={handleLogin}
            className="bg-black font-semibold text-white p-3 rounded-lg w-1/3"
            >
            login
            </button>
        </div>
    </div>

  );
};

export default AdminLogin;
