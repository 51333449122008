import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png';  // Ensure the path is correct

const NotFound = () => {
  return (
    <motion.div 
      className="min-h-screen flex flex-col items-center justify-start text-center text-white px-16 py-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Container for content */}
      <div className="flex flex-col items-center max-w-md mx-auto">
        {/* Logo */}
        <img src={logo} alt="Unplug Logo" className="w-16 md:w-20 lg:w-32 mb-6" />

        {/* 404 Text */}
        <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4">404</h1>

        {/* Error Message */}
        <h2 className="font-semibold text-xl md:text-2xl lg:text-3xl mb-4">
          Oops! Looks like this page may have been unplugged.
        </h2>

        {/* Apology Text */}
        <p className="font-medium text-md md:text-lg lg:text-xl mb-6">
          We're really sorry about that. Please try the link below.
        </p>

        {/* Back Home Button */}
        <Link 
          to="/" 
          className="bg-black text-white font-bold py-2 px-4 rounded-lg text-lg transition-all"
        >
          Go Back Home
        </Link>
      </div>
    </motion.div>
  );
};

export default NotFound;
